import axios from 'axios'

export interface ILoginResponse {
    id:number,
    name: string,
    login: string,
    email: string,
    token: string,
    picture: string,
    role_id: string,
}

export default {
    async login( login: string, password: string) {

        const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'user/' || "";
        
        const url = baseUrl+'/loginAgentV2.php';

        return await axios.post<ILoginResponse>(url,
        { login, password},
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }).then( request  => 
        {
          
          localStorage.setItem("auth",request.data.token);
          localStorage.setItem("user",request.data.name);
          localStorage.setItem("login",request.data.login);
          localStorage.setItem("email",request.data.email);
          localStorage.setItem("id",request.data.id.toString());
          localStorage.setItem("picture", request.data.picture);
          localStorage.setItem("role", request.data.role_id);
          
          return 1;
          
        }).catch( err => {
          console.log(err)
          return 0;
        });
    }
}