import { useEffect, useState } from "react";

import { ComponentWrapper, ContentWrapper, FilterInput, InputsWrapper, ResultWrapper, SubWrapper, TableGrid, Title, ValueWrapper, Wrapper } from "./style";

import ReportService from "../../../../service/reportService";

import "react-datepicker/dist/react-datepicker.css";
import { ReportLine } from "./types";
import Paginationbar from "../paginationBar";
import { Header } from "../../Webhook/ListWebhook/styles";
import { AppBar, Box, Paper, Table, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from "@mui/material";
import { CreateButton } from "../../PredefinedMessagesManager/styles";
import { FaPlus } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { ErrorSpan } from "../../Departament/NewDepartament/styles";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

interface IPagObject {
  total: number,
  offset: number,
}

const ShowReport: React.FC = () => {

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [resultSearch, setResultSearch] = useState<ReportLine[]>([]);
  const [pagnationState, setPaginationState] = useState<IPagObject>({ offset: 0, total: 0 });

  const getAllResults = async () => {
    const start = startDate.toJSON().slice(0, 10);
    const end = endDate.toJSON().slice(0, 10);
    const result = await ReportService.getFilteredReport(start, end, searchFilter, 0, -1);
    return result.reports;
  }

  const { register, formState: { errors } } = useForm({});
  const downloadFile = ({ data, fileName, fileType }: any) => {
    const blob = new Blob([data], { type: fileType })

    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  const exportToCsv = async () => {
    const jsonData = await getAllResults();
    let headers = ["id,name,phone,picture,messages,department,company,is_group,archived,created_at,updated_at,ticket_number"]

    let csv = jsonData.reduce((chats: any, chat: any) => {
      const {
        id,
        name,
        phone,
        picture,
        messages,
        department,
        company,
        is_group,
        archived,
        created_at,
        updated_at,
        ticket_number
      } = chat
      chats.push([id, name, phone, picture, messages, department, company, is_group, archived, created_at, updated_at, ticket_number].join(','))
      return chats
    }, [])

    downloadFile({
      data: [...headers, ...csv].join('\n'),
      fileName: 'chats.csv',
      fileType: 'text/csv',
    })
  }

  useEffect(() => {
    const start = startDate.toJSON().slice(0, 10);
    const end = endDate.toJSON().slice(0, 10);
    ReportService.getFilteredReport(start, end, searchFilter, pagnationState.offset, 10)
      .then((result: any) => {
        setResultSearch(result.reports);
        setPaginationState((state) => (
          {
            offset: pagnationState.offset,
            total: result.total
          }
        ));
      });
  }, [endDate, pagnationState.offset, searchFilter, startDate]);

  return (
    <ComponentWrapper>
      <Wrapper>
        <Header>
          <Box sx={{ display: 'flex' }}>
            <AppBar position="static" sx={{ backgroundColor: 'var(--background-company)', padding: '0.2rem', paddingTop: '0' }} >
              <Toolbar sx={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                  <h2> Relatórios</h2>
                </Typography>
                <div style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center', height: '40%' }}>
                  <CreateButton onClick={() => { exportToCsv() }} endIcon={<FaPlus />} ><h4>Exportar</h4></CreateButton>
                </div>
              </Toolbar>
            </AppBar>
          </Box>
        </Header>

        <InputsWrapper>
          <FilterInput>

            <TextField
              sx={{
                '& .MuiInput-underline:before': { borderBottomColor: 'orange' },
                '& .MuiInput-underline:after': { borderBottomColor: 'orange' },
              }}
              key="namePhone"
              autoFocus
              margin="dense"
              id="namePhone"
              error={errors.namePhone ? true : false}
              label="Nome"
              placeholder="Nome do cliente"
              type="text"
              {...register("namePhone")}
              onChange={e => setSearchFilter(e.target.value)}
              value={searchFilter}
              variant="filled"
              InputLabelProps={{ shrink: true, style: { color: 'var(--text-modal)' } }}
            />
            {errors.namePhone && <ErrorSpan>Este campo precisa de pelo menos dois caracteres!</ErrorSpan>}

            <DemoContainer components={[]}>
              <DatePicker label={'Data atual'} format="dd/MM/yy" defaultValue={startDate} onChange={(date => setStartDate(date!))} />
            </DemoContainer>

            <DemoContainer components={[]}>
              <DatePicker label={'Data final'} format="dd/MM/yy" defaultValue={endDate} onChange={(date => setEndDate(date!))} />
            </DemoContainer>

          </FilterInput>
        </InputsWrapper>

        <SubWrapper>
          <ContentWrapper>

            <TableContainer component={Paper} sx={{ width: '97%', alignContent: 'center', alignSelf: 'center', marginTop: '2rem', color: 'var(--text-primary)', borderRadius: '0', textAlign: "center" }}>
              <Table sx={{ minWidth: 350, backgroundColor: 'var(--wrapper-input-admin-background)' }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: 'var(--text-primary)', width: "1%", textAlign: "center" }}>Telefone</TableCell>
                    <TableCell sx={{ color: 'var(--text-primary)', width: "40%", textAlign: "center" }}>Nomes</TableCell>
                    <TableCell sx={{ color: 'var(--text-primary)', width: "20%", textAlign: "center" }}>Empresa</TableCell>
                    <TableCell sx={{ color: 'var(--text-primary)', width: "40%", textAlign: "center" }}>Departamento</TableCell>
                    <TableCell sx={{ color: 'var(--text-primary)', width: "5%", textAlign: "center", maxWidth: "4.5rem" }}>Mensagens</TableCell>
                    <TableCell sx={{ color: 'var(--text-primary)', width: "5%", textAlign: "center", maxWidth: "2rem" }}>Grupo</TableCell>
                    <TableCell sx={{ color: 'var(--text-primary)', width: "5%", textAlign: "center" }}>Arquivado</TableCell>
                  </TableRow>
                </TableHead>

                {resultSearch.map(report => (
                  <TableRow
                    key="TableDepartments"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, textAlign: "center" }}>
                    <TableCell component="th" scope="row">
                      {report.phone}
                    </TableCell>

                    <TableCell component="th" scope="row" sx={{ textAlign: "center" }}>
                      {report.name}
                    </TableCell>


                    <TableCell component="th" scope="row" sx={{ textAlign: "center" }}>
                      {report.company}
                    </TableCell>

                    <TableCell component="th" scope="row" sx={{ textAlign: "center" }}>
                      {report.department}
                    </TableCell>

                    <TableCell component="th" scope="row" sx={{ textAlign: "center" }}>
                      {report.messages}
                    </TableCell>

                    <TableCell component="th" scope="row" sx={{ textAlign: "center" }}>
                      {report.is_group === 0 ? 'Não' : 'Sim'}
                    </TableCell>


                    <TableCell component="th" scope="row" sx={{ textAlign: "center" }}>
                      {report.archived === 0 ? 'Não' : 'Sim'}
                    </TableCell>
                  </TableRow>
                ))}

              </Table>
            </TableContainer>

            <Paginationbar
              limit={10}
              total={pagnationState.total}
              offset={pagnationState.offset}
              onChange={(num: number) => setPaginationState((state) => {
                return {
                  ...state,
                  offset: num
                }
              })}
            />
          </ContentWrapper>
        </SubWrapper>
      </Wrapper>
    </ComponentWrapper >
  )
}

export default ShowReport;
