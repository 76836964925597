import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import BigOkButton from "../../Buttons/BigOkButton";
import FormPasswerdInput from "../../FormPasswordInput";
import FormTextInput from "../../FormTextInput";
import {
  TitleContainer,
  FormWrapper,
  InputsContainer,
  UserDataWrapper,
  NewUserForm,
  CheckboxContainer,
  CompanyCheckboxWrapper,
  SelectContainer
} from "./styles";
import departmentService from "../../../../service/departmentService";
import FormSelectInput from "../../FormSelectInput";
import { UserRole } from "../../../../util/enums";
import userService from "../../../../service/userService";
import { useNavigate } from "react-router-dom";
import FormSelectedItem from "../../FormSelectedItem";
import { useForm } from "react-hook-form";
import FormEmailInput from "../../FormEmailInput";

import SelectCustom from '../../../Select/';
import Select, {MultiValue,SingleValue,ActionMeta} from 'react-select';
import * as S from './styles';

const NewUser: React.FC = () => {

  const nav = useNavigate();

  const companys = useAppSelector(state => state.chat.companys);
  const companyOptions = [...companys.map(company => (
    {
      value: String(company.id), label: company.name
    }
  ))];

  const { register, handleSubmit, formState: { errors } } = useForm();


  const [showPassState, setShowPassState] = useState<boolean>(false);

  const [selectedCompanysState, setSelectedCompanysState] = useState<any[]>([]);

  const [selectedDepartamentsState, setSelectedDepartamentsState] = useState<any[]>([]);
  const [departamentsState, setDepartamentsState] = useState<any[]>([]);
  const [departamentsOptionsState, setDepartamentsoptionsState] = useState<any[]>([]);

  const [selectedoptionRole, setSelectedOptionRole] = useState<string>(UserRole.agent.toString());

  const roleOptions = [
    {value: UserRole.master.toString(), label: 'Administrador'},
    {value: UserRole.agent.toString(), label: 'Agente'},
  ];

  useEffect(() => {
    const departamentOptions = departamentsState.map((dep: any) => (
      {
        value: dep.id,
        label: `${dep.name} - ${dep.companyName}`
      }
    ))
    setDepartamentsoptionsState(departamentOptions);
  }, [departamentsState]);

  const handleRoleSelect = useCallback((newValue:{ value: string; label: string; }, actionMeta:ActionMeta<any>) => {
    setSelectedOptionRole(newValue.value);
  }, [ setSelectedOptionRole ]);

  const handleCompanySelect = useCallback((newValue:{ value: string; label: string; }, actionMeta:ActionMeta<any>) => {
    if (
      selectedCompanysState.find(el => el.value === newValue.value) === undefined
    ) {
      setSelectedCompanysState([...selectedCompanysState, newValue]);
      departmentService.getDepartmentByCompanyID(newValue.value as unknown as number)
      .then(result => {
        if (result !== false) {
          let array1 = departamentsState
          let array2 =result.map((el: any) => (
            {
              id: el.id,
              companyId: newValue.value,
              companyName: newValue.label,
              name: el.name
            }
          ))
          // let array3 = array1.concat(array2)
          let set = new Set([...array1,...array2])
          setDepartamentsState(Array.from(set));

        }
      })
    }
  }, [ selectedCompanysState, departamentsState ]);

  const handleRemoveCompany = useCallback((companyId: string) => {
    const newState = selectedCompanysState.filter( el => el.value !== companyId)
    const newSelectedDepsState = selectedDepartamentsState.filter(el => el.companyId !== companyId)
    const newDepsState = departamentsState.filter(el => el.companyId !== companyId)
    setSelectedCompanysState(newState);
    setSelectedDepartamentsState(newSelectedDepsState);
    setDepartamentsState(newDepsState);
  }, [ selectedCompanysState, selectedDepartamentsState, departamentsState ]);

  const handleDepartamentSelect = useCallback((newValue:{ value: string; label: string; }, actionMeta:ActionMeta<any>) => {
    if (
      selectedDepartamentsState.find(el => el.id === newValue.value) === undefined
    ) {
      setSelectedDepartamentsState(
        [
          ...selectedDepartamentsState,
          departamentsState.filter(el => el.id === newValue.value)[0]
        ]
        );
    }
  }, [ selectedDepartamentsState, departamentsState ]);

  const handleRemoveDepartament = useCallback((departamentId: string) => {
    const newState = selectedDepartamentsState.filter( el => el.id !== departamentId);
    setSelectedDepartamentsState([...newState]);
  }, [ selectedDepartamentsState ]);

  const onSubmit = useCallback((data: any) => {
    try {
      userService.createUser(
        data.login,
        data.name,
        data.email,
        data.password,
        data.picture,
        1,
        selectedCompanysState.map( el => parseInt(el.value)),
        selectedDepartamentsState.map( el => el.id),
        selectedoptionRole,
      ).then(response => {
        nav('/admin/users')
      })
      .catch(err => console.log(err))
    } catch (error) {
      console.log(error);
    }
  }, [ selectedCompanysState, selectedDepartamentsState, selectedoptionRole, nav ]);


  const customStyles = {
    control: (provided:any, state:any) => ({
      ...provided,
        border: '0px solid transparent',
        borderRadius:'25px',
        // margin: '5px 10px',
        borderTop:'1px solid var(--border-color)',
        ':hover': {
            borderTop:'1px solid var(--border-color)'
        }
    }),
    multiValue: (provided:any, state:any) => ({
        ...provided,
        backgroundColor: 'var(--background-company)',
        color: 'var(--text-company)',
        borderRadius:25,
        placeholder:'var(--text-primary)',
        
    }),
    singleValue: (provided:any, state:any) => ({
      ...provided,
      backgroundColor: 'var(--background-company)',
      color: 'var(--text-company)',
      borderRadius:25,
      padding:"4px 10px",
      width: 'auto',
      placeholder:'var(--text-primary)',
      
  }),
    multiValueLabel: (provided:any, state:any) => ({
        ...provided,
        color: 'var(--text-company)',
    }),
    multiValueRemove: (provided:any, state:any) => ({
        ...provided,
        ':hover': {
            backgroundColor: 'var(--text-primary)',
            color: 'var(--bar-background)',
            borderRadius:8,
        }
    }),
     option: (provided:any, state:any) => ({
        ...provided,
        color:'var(--text-primary)',
        ':hover':{
          color:'#fff'
        }
    }),
    input: (provided:any, state:any) => ({
        ...provided,
        color:'var(--text-primary)',
    }),
  };
  
  return (
    <FormWrapper onSubmit={() => {}}>
      <UserDataWrapper>
        <TitleContainer>
          Novo Usuário
        </TitleContainer>
        <NewUserForm>
          <InputsContainer>
            <FormTextInput
              label="Login"
              placeholder="Login do usuário"
              register={register}
              errors={errors.login}
              name='login'
            />
            <FormTextInput
              label="Nome"
              placeholder="Nome do usuário"
              register={register}
              errors={errors.name}
              name='name'
            />
            <FormEmailInput
              label="Email"
              placeholder="Email do usuário"
              register={register}
              errors={errors.email}
              name='email'
            />
            <FormPasswerdInput
              label="Senha"
              placeholder="Nome do usuário"
              showpass={showPassState}
              handleChangeShow={setShowPassState}
              register={register}
              errors={errors.password}
              required={true}
              name='password'
            />
            <>
              <FormTextInput
                label="Link da foto"
                placeholder="Nome do usuário"
                register={register}
                errors={errors.picture}
                name='picture'
                required={false}
              />
            </>
            <FormSelectInput
              label="Tipo de usuário"
              placeholder="Tipo"
              options={roleOptions}
              handleOnChange={handleRoleSelect}
            />
            <FormSelectInput
              label="Empresas"
              placeholder="Tipo"
              options={companyOptions}
              handleOnChange={handleCompanySelect}
            />
            <CompanyCheckboxWrapper>
              {selectedCompanysState.map(company => (
                  <FormSelectedItem
                    key={company.value}
                    id={company.value}
                    label={company.label}
                    removeFunc={handleRemoveCompany}
                  />
                ))}
            </CompanyCheckboxWrapper>
          </InputsContainer>
        <BigOkButton handleFunction={handleSubmit(onSubmit)}/>
      </NewUserForm>
    </UserDataWrapper>

    <UserDataWrapper>

      <TitleContainer>
        Relações
      </TitleContainer>

      <S.WrapperRelations>
        <S.WrapperRelationField>
              <S.LabelSelect>Empresas</S.LabelSelect>
        </S.WrapperRelationField>
        <SelectCustom options={companyOptions} onSelected={ (content) => {} } />

        <Select 
        options={departamentsState}
        isMulti={true} 
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: 'var(--background-company)',
            primary: 'var(--bar-background)',
            neutral0: 'var(--bar-background)'
          },
        })}  
      />
      </S.WrapperRelations>
   
     
      <TitleContainer>
      Departamentos
      </TitleContainer>
      <SelectContainer>
        <FormSelectInput
          label=""
          placeholder="Departamentos"
          options={departamentsOptionsState}
          handleOnChange={handleDepartamentSelect}
        />
      </SelectContainer>
      <CheckboxContainer>
        {selectedDepartamentsState.map(dep => (
          <FormSelectedItem
          key={dep.id}
          id={dep.id}
          label={`${dep.name} - ${dep.companyName}`}
          removeFunc={handleRemoveDepartament}
        />
        ))}
      </CheckboxContainer>
    </UserDataWrapper>

    

    </FormWrapper>
  );
};

export default NewUser;
