import axios from 'axios'

export interface IRelationCompany{
    id_company: string,
    name_company: string,
    url_company?: string
}

export type IRelationsComapnys = {
    companys: IRelationCompany[],
}

export default {
    async getRelationUserCompany() {

        const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'user/' || "";

        const tokenJWT = localStorage.getItem("auth");

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
        } 

        const url = baseUrl+'/getRelationUserCompany.php';

        const id_user = localStorage.getItem("id");
        return await axios.post<IRelationCompany[]>(url,
        { id_user: id_user},
        {
          headers
        }).then( request  => 
        {   
            return request.data;
        }).catch( err => {
            
            throw err;
        });
    },
    async listAllUsers() {
        const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'user/' || "";

        const tokenJWT = localStorage.getItem("auth");

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
        } 

        const url = baseUrl+'/listUsersV2.php';

        return await axios.post<IRelationCompany[]>(url,
            {},
            {
              headers
            }).then( request  => 
            {   
                return request.data;
            }).catch( err => {
                
                throw err;
            });
    },
    async createUser(
        name: string,
        login: string,
        email: string,
        password: string,
        picture: string,
        valid_id: number,
        companys: number[],
        departments: number[],
        role_id: string
    ) {
        const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'user/' || "";

        const tokenJWT = localStorage.getItem("auth");

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
        } 

        const url = baseUrl+'/createUserV2.php';

        return await axios.post(url,
            {
                name: name,
                login: login,
                email: email,
                password: password,
                picture: picture,
                valid_id: valid_id,
                companys: companys,
                departments: departments,
                role_id: role_id
            },
            {
              headers
            }).then( request  => 
            {   
                return request.data;
            }).catch( err => {
                throw err;
            });
    },
    async deleteUser(id: number) {
        const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'user/' || "";

        const tokenJWT = localStorage.getItem("auth");

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
        } 

        const url = baseUrl+'/deleteUser.php';

        return await axios.post(url,
            {
                id: id
            },
            {
              headers
            }).then( request  => 
            {
                return request.data;
            }).catch( err => {
                throw err;
            });
    },
    async updateUser(
        userId: string,
        name: string,
        login: string,
        email: string,
        password: string,
        picture: string,
        valid_id: number,
        companys: number[],
        departments: number[],
        role_id: string
        ) {
        const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'user/' || "";

        const tokenJWT = localStorage.getItem("auth");

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
        } 

        const url = baseUrl+'/updateUserV2.php';

        return await axios.post(url,
            {
                id: userId,
                name: name,
                login: login,
                email: email,
                password: password,
                picture: picture,
                valid_id: valid_id,
                companys: companys,
                departments: departments,
                role_id: role_id
            },
            {
              headers
            }).then( request  => 
            {
                return request.data;
            }).catch( err => {
                throw err;
            });
    },
    async getUser(
        userId: string
        ) {
        const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'user/' || "";

        const tokenJWT = localStorage.getItem("auth");

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
        } 

        const url = baseUrl+'getUserV2.php';

        return await axios.post(url,
            {
                id: userId,
            },
            {
              headers
            }).then( request  => 
            {
                return request.data;
            }).catch( err => {
                throw err;
            });
    },
    async listUserByDepartament(
        departmentId: string
        ) {
        const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'user/' || "";

        const tokenJWT = localStorage.getItem("auth");

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
        } 

        const url = baseUrl+'/listUserByDepartment.php';

        return await axios.post(url,
            {
                department_id: departmentId,
            },
            {
              headers
            }).then( request  => 
            {
                return request.data;
            }).catch( err => {
                throw err;
            });
    },
    async listUserByCompanyID(
        companyId: number
        ) {
        const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'user/' || "";

        const tokenJWT = localStorage.getItem("auth");

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
        } 

        const url = baseUrl+'/listUserByCompany.php';

        return await axios.post(url,
            {
                company_id: companyId,
            },
            {
              headers
            }).then( request  => 
            {
                return request.data;
            }).catch( err => {
                throw err;
            });
    },
    async updateUserProfile(login: string, name: string, picture: string){

        const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'user/' || "";

        const tokenJWT = localStorage.getItem("auth");
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
        } 
        
        const urlRequest = baseUrl + "updateProfileV2.php";

        return await axios.post<{status:boolean}>(urlRequest,{
            login: login,
            name: name,
            picture: picture,
        },{headers})
        .then( response => {
            return response.data;
        })
        .catch( error => {
            return { status: false, error: error.message }
        });
    
    },
    async resetPassword(old_password: string, new_password: string){

        const baseUrl:string = process.env.REACT_APP_URL_APP_BOT_API + 'user/' || "";

        const tokenJWT = localStorage.getItem("auth");

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenJWT}`,
        } 
        
        const urlRequest = baseUrl + "resetPassword.php";

        return await axios.post<{status:boolean}>(urlRequest,{
           old_password: old_password,
           new_password: new_password,
        },{headers})
        .then( response => {
            return response.data
        })
        .catch( error => {
            return { status: false}
        });
    }
}
