import { ArrowBack, Edit, Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, AppBar, Box, Button, IconButton, Snackbar, TextField, Toolbar, Typography } from "@mui/material";
import md5 from "md5";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as zod from 'zod';
import userService from "../../service/userService";
import { ButtonWrapper, FormWrapper, FullPageWrapper, Header, Icon, ImageAvatar, ImageAvatarClicked, ImageContainer, Main, styles, Wrapper, WrapperButtonBack } from "./styles";

const newPasswordFormValitionSchema = zod.object({
  newPassword: zod.string().trim().min(6, 'Toda senha deve ter no mínimo 6 caracteres'),
  oldPassword: zod.string().trim().min(6, 'Toda senha deve ter no mínimo 6 caracteres'),
  login: zod.string().trim().min(3, 'Um login deve ter pelo menos 3 letras.'),
  name: zod.string().trim().min(3, 'Um nome deve ter pelo menos 3 letras.'),
  email: zod.string(),
  URLProfile: zod.string(),
})
type NewPasswordFormdata = zod.infer<typeof newPasswordFormValitionSchema>

type AlertColor = 'success' | 'info' | 'warning' | 'error';

export interface Snackbar {
  open: boolean;
  message: string;
  severity: AlertColor;
}


export const ProfilePage: React.FC = () => {
  const [num, setNum] = React.useState();

  const [changePassword, setChangePassword] = React.useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const [IsAvatarClicked, setAvatarClicked] = React.useState<boolean>(false);
  const [IsPasswordFalse, setPasswordFalse] = React.useState<boolean>(false);

  const [HoverAnchor, setHoverAnchor] = React.useState<HTMLElement | null>(null);

  const [agentProfilePicture, setAgentProfilePicture] = useState<string>("");

  const [ErrorTextNewPassword, setErrorTextnewPassword] = useState<string>("");
  const [ErrorTextOldPassword, setErrorTextOldPassword] = useState<string>("");

  const [snackBar, setSnackBar] = useState<Snackbar>({ open: false, message: '', severity: 'info' })


  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const nav = useNavigate();

  const setData = () => {

    reset();

    if (changePassword) return;

    let email = localStorage.getItem("email") || "";
    let login = localStorage.getItem("login") || "";
    let user = localStorage.getItem("user") || "";
    let pictureLocal = localStorage.getItem("picture");

    let encodingEmail = md5(email);

    let url = "";
    if (!pictureLocal) {

      url = "https://www.gravatar.com/avatar/" + encodingEmail + "?s=2500";

      if (encodingEmail) {
        setAgentProfilePicture(url)
      } else {
        setAgentProfilePicture(process.env.PUBLIC_URL + '/user.png');
      }
    } else {
      setAgentProfilePicture(pictureLocal);
      url = pictureLocal;
    }

    setValue('name', user)
    setValue('email', email)
    setValue('login', login)
    setValue('URLProfile', url)
  }

  useEffect(() => {
    setData();
  }, [changePassword]);

  const NewPasswordForm = useForm<NewPasswordFormdata>({
    // resolver: zodResolver(newPasswordFormValitionSchema),
    // defaultValues: {
    //   newPassword: '',
    //   oldPassword: '', 
    //   name: '',
    //   email:'',
    //   URLProfile: ''
    // }
  })

  const inputRef = useRef<HTMLInputElement | null>(null);
  const { handleSubmit, reset, register, setValue, formState: { errors } } = NewPasswordForm

  const handleButtonClick = () => {
    inputRef.current?.focus();
    setAvatarClicked(true);
    setChangePassword(false);
    if (inputRef.current == null) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, .1);
    }
    setTimeout(() => {
      setAvatarClicked(false)
    }, 5000);
  };

  const HandleDefaultSubmit = (data: NewPasswordFormdata) => {

    if (changePassword) {
      setErrorTextnewPassword('')
      setErrorTextOldPassword('')
      setPasswordFalse(false)
      reset()

      if (data.newPassword == data.oldPassword) {
        setPasswordFalse(true)
        setErrorTextnewPassword('sua nova senha não pode ser igual sua senha antiga')

        return;
      }

      userService.resetPassword(data.oldPassword, data.newPassword)
        .then(response => {

          if (!response.status) {
            setSnackBar({ open: true, message: `Erro ao resetar senha do usuário`, severity: 'error' });
            return;
          }

          setSnackBar({ open: true, message: `Senha do usuário alterada com sucesso!`, severity: 'success' });

        })
        .catch(error => {
          setSnackBar({ open: true, message: `Erro ao resetar senha do usuário`, severity: 'error' });
        })

    } else {

      userService.updateUserProfile(data.login, data.name, data.URLProfile)
        .then(response => {
          setSnackBar({ open: true, message: `Usuário atualizado`, severity: 'success' });

          localStorage.setItem("user", data.name);
          localStorage.setItem("login", data.login);
          localStorage.setItem("picture", data.URLProfile);
        })
        .catch(error => {
          setSnackBar({ open: true, message: `Erro ao atualizar usuário`, severity: 'error' });
        })
    }
  }


  const handleHoverOpen = (event: React.MouseEvent<HTMLElement>) => {

    setHoverAnchor(event.currentTarget);
  };

  const handleHoverClose = () => {
    setHoverAnchor(null);
  };

  const handleChange = (e: any) => {
    const regex = /^[A-Za-z ]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setNum(e.target.value);
    }
  };

  const handleChangePassword = () => {
    setChangePassword(!changePassword);
  };

  const handleCloseSnack = () => {
    setSnackBar({
      open: false,
      message: '',
      severity: 'info',
    })
  }
  const theme = localStorage.getItem('theme');

  return (
    <FullPageWrapper data-theme={theme ? theme : 'light'}>
      <Wrapper>

        <Main>
          <Header>
            <Box>
              <AppBar position="static" sx={{ backgroundColor: 'var(--background-company)', padding: '0.5rem', paddingTop: '0' }} >
                <Toolbar sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, marginBottom: '-20px', display: { xs: 'none', sm: 'block' } }}>
                    <h2> Perfil</h2>
                  </Typography>
                  <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, margin: '-20px 0px', display: { xs: 'none', sm: 'block' } }}>
                    <p> Alterar informações de usuário</p>
                  </Typography>
                </Toolbar>
              </AppBar>
            </Box>
          </Header>

          <FormWrapper>
            <form onSubmit={handleSubmit(HandleDefaultSubmit)} className="FormEditarUsuario">
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackBar.open}
                autoHideDuration={6000}
              >
                <Alert onClose={handleCloseSnack} severity={snackBar.severity} sx={{ width: '100%' }}>
                  {snackBar.message}
                </Alert>
              </Snackbar>
              {IsAvatarClicked ?
                <ImageContainer>
                  <ImageAvatarClicked
                    src={agentProfilePicture} />
                  <Icon>
                    <Edit sx={{ height: '2rem', width: '2rem' }}></Edit>
                  </Icon>
                </ImageContainer>
                :
                <ImageContainer>
                  <ImageAvatar
                    onClick={handleButtonClick}
                    aria-haspopup="true"
                    onMouseEnter={handleHoverOpen}
                    onMouseLeave={handleHoverClose}
                    src={agentProfilePicture} />
                  <Icon>
                    <Edit sx={{ height: '2rem', width: '2rem' }}></Edit>
                  </Icon>
                </ImageContainer>}

              {changePassword ?
                <TextField id="filled-basic oldPassword"
                  label="Senha atual"
                  placeholder="Senha atual"
                  //  required={changePassword}        
                  helperText={IsPasswordFalse ? ErrorTextOldPassword : errors.oldPassword?.message}
                  variant="filled"
                  {...register('oldPassword', { required: 'Campo requerido', minLength: { value: 8, message: 'Minimo de 8 caracteres' } })}
                  fullWidth
                  InputLabelProps={{ shrink: true, style: { color: 'var(--text-primary)' } }}
                  type={showPassword ? 'text' : 'password'}
                  sx={{ marginTop: styles.inputMarginTop, backgroundColor: 'var(--input-background)' }}
                  InputProps={{
                    endAdornment: (
                      <IconButton sx={{ backgroundColor: 'none' }} color="primary" onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff sx={{ color: 'var(--text-primary)' }} /> : <Visibility sx={{ color: 'var(--text-primary)' }} />}
                      </IconButton>
                    ),
                  }}
                />
                :
                <TextField id="filled-basic"
                  label="Login"
                  variant="filled"
                  placeholder="Login"
                  fullWidth
                  {...register('login', { required: 'Campo requerido', minLength: { value: 3, message: 'Minimo de 3 caracteres' } })}
                  helperText={errors.login?.message}
                  InputLabelProps={{ shrink: true, style: { color: 'var(--text-primary)' } }}
                  sx={{ marginTop: styles.inputMarginTop }}
                />
              }

              {changePassword ?
                null
                :
                <TextField id="filled-basic"
                  label="Nome"
                  variant="filled"
                  placeholder="Nome"
                  fullWidth
                  {...register('name', { required: 'Campo requerido', minLength: { value: 3, message: 'Minimo de 3 caracteres' } })}
                  helperText={errors.name?.message}
                  InputLabelProps={{ shrink: true, style: { color: 'var(--text-primary)' } }}
                  sx={{ marginTop: styles.inputMarginTop }}
                />
              }

              {changePassword ?
                null
                :
                <TextField id="filled-basic"
                  label="Email"
                  placeholder="Email"
                  variant="filled"
                  InputLabelProps={{ shrink: true, style: { color: 'var(--text-primary)' } }}
                  fullWidth sx={{ marginTop: styles.inputMarginTop }}
                  {...register('email')}

                  InputProps={{
                    readOnly: true,
                  }} />
              }

              {changePassword ?

                <TextField id="filled-basic newPassword"
                  label="Nova senha"
                  placeholder="Nova senha"
                  // required={changePassword}
                  {...register('newPassword', { required: 'Campo requerido', minLength: { value: 8, message: 'Minimo de 8 caracteres' } })}
                  error={IsPasswordFalse}
                  helperText={IsPasswordFalse ? ErrorTextNewPassword : errors.newPassword?.message || errors.newPassword?.message}
                  variant="filled"
                  fullWidth
                  InputLabelProps={{ shrink: true, style: { color: 'var(--text-primary)' } }}
                  sx={styles.inputMarginTop}
                  type={showNewPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <IconButton color="primary" onClick={handleClickShowNewPassword}>
                        {showNewPassword ? <VisibilityOff sx={{ color: 'var(--text-primary)' }} /> : <Visibility sx={{ color: 'var(--text-primary)' }} />}
                      </IconButton>
                    ),
                  }} />
                :
                <TextField id="filled-basic"
                  label="URL de perfil"
                  variant="filled"
                  placeholder="URl de perfil"
                  InputLabelProps={{ shrink: true, style: { color: 'var(--text-primary)' } }}
                  {...register('URLProfile')}
                  inputRef={inputRef}
                  fullWidth
                  sx={styles.inputMarginTop} />
              }
              <footer className="footerBtn">
                <ButtonWrapper>
                  {changePassword ?
                    <Button variant="outlined" startIcon={<ArrowBack />}
                      sx={{ border: 'none', color: 'var(--background-company)', marginTop: styles.inputMarginTop, '&:hover': { border: 'none' } }}
                      onClick={handleChangePassword}>
                      Clique aqui para voltar
                    </Button>
                    :
                    <Button variant="outlined"

                      startIcon={<Lock />}
                      sx={{ border: 'none', color: 'var(--background-company)', marginTop: styles.inputMarginTop, '&:hover': { border: 'none' } }}
                      onClick={handleChangePassword}>
                      Troque sua senha
                    </Button>}


                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      marginTop: styles.inputMarginTop, width: '45%', color: 'var(--text-department)', justifyContent: 'center', padding: '1rem', backgroundColor: 'var(--btn-submit)',
                      '&:hover': { backgroundColor: 'var(--btn-submit-hover)' }
                    }}>
                    Atualizar</Button>
                </ButtonWrapper>
              </footer>
            </form>



            <WrapperButtonBack>
              <Button
                variant="contained"
                onClick={() => nav("/chat")}
                sx={{ backgroundColor: '#ccc', color: '#333333', '&:hover': { backgroundColor: '#ccc', color: '#333333' } }}
              >
                Voltar para o chat
              </Button>
            </WrapperButtonBack>
          </FormWrapper>


        </Main>
      </Wrapper>
    </FullPageWrapper>
  )
}